import React, { useState, useEffect } from "react";

// Third party imports
import { useNavigate } from "react-router-dom";
import { TextField, Button, Box, Stack, CircularProgress } from "@mui/material";
import Table from "@mui/joy/Table";
import axios from "axios";

export function Home() {

    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [offset, setOffset] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [data, setData] = useState([]);

    const navigate = useNavigate()

    const fetchHomeData = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/?limit=${10}&offset=${offset}&search=${searchQuery}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status) {
                setData(response.data.data.context);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setOffset(0); // Reset to first page on new search
    };

    const handlePreviousPage = () => {
        if (offset > 0) {
            setOffset((prevOffset) => prevOffset - 10);
        }
    };

    const handleNextPage = () => {
        if (offset + 10 < totalCount) {
            setOffset((prevOffset) => prevOffset + 10);
        }
    };

    const handleChatNow = (row) => {
        console.log("Chat Now clicked for:", row);
        navigate(`/chat/${row.value}`)
    };


    useEffect(() => {
        fetchHomeData();
    }, []);

    return (
        <Box sx={{ padding: 4 }}>
            {/* Search Bar */}
            <Stack direction="row" spacing={2} mb={3}>
                <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={handleSearch}
                    sx={{ flexGrow: 1 }}
                />
            </Stack>

            {/* Table */}
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                    <CircularProgress />
                </Box>
            ) : (
                <Table
                    aria-label="simple table"
                    stickyHeader
                    sx={{
                        minWidth: 650,
                        border: "1px solid #e0e0e0",
                        borderRadius: "8px",
                        overflow: "hidden",
                    }}
                >
                    <thead>
                        <tr>
                            {data.length > 0 &&
                                Object.keys(data[0]).map((key) => (
                                    <th key={key} style={{ padding: "10px", textAlign: "left" }}>
                                        {key.toUpperCase()}
                                    </th>
                                ))}
                            <th>
                                ACTION
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index}>
                                {Object.values(row).map((value, i) => (
                                    <td key={i} style={{ padding: "10px", textAlign: "left" }}>
                                        {value}
                                    </td>
                                ))}
                                <td style={{ padding: "10px", textAlign: "left" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleChatNow(row)}
                                    >
                                        Chat Now
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            {/* Pagination Controls */}
            <Stack direction="row" justifyContent="space-between" mt={3}>
                <Button
                    variant="contained"
                    disabled={offset === 0}
                    onClick={handlePreviousPage}
                >
                    Previous
                </Button>
                <Button
                    variant="contained"
                    disabled={offset + 10 >= totalCount}
                    onClick={handleNextPage}
                >
                    Next
                </Button>
            </Stack>
        </Box>
    );
}

export default Home