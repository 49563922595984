// App.js

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ChatComponent from "./Pages/Bot";
import BotHistory from "./Pages/botHistory";
import AdminComponent from "./Pages/Admin";
import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/chat/:id" element={<ChatComponent />} />
        <Route path="/" element={<Home />} />
        <Route path="/history" element={<BotHistory />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
