import { Link } from "react-router-dom";
import { Box, Typography, Button, Container } from "@mui/material";

const NotFound = () => {
    return (
        <Container
            maxWidth="sm"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                textAlign: "center",
            }}
        >
            <Typography variant="h1" color="error" fontWeight="bold">
                404
            </Typography>
            <Typography variant="h5" sx={{ mt: 2, fontWeight: "bold" }}>
                Oops! Page not found.
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
                The page you are looking for does not exist.
            </Typography>
            <Button
                component={Link}
                to="/"
                variant="contained"
                color="primary"
                sx={{ mt: 3 }}
            >
                Go to Home
            </Button>
        </Container>
    );
};

export default NotFound;
