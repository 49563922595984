import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import axios from "axios";
import "transition-style";
import "transition-style/transition.circles.min.css";
import Markdown from "react-markdown";
import { useParams } from "react-router-dom";

function ChatComponent() {
  const chatDisplayRef = useRef(null);
  const fileInputRef = useRef(null);
  const { id } = useParams();
  const [Userdata, setUserData] = useState({
    userInput: "",
    pdf_file: null,
    selected_pdf: [],
    pdf_file_name: "",
  });

  const [selectedPdfFiles, setSelectedPdfFiles] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [pdflist, setPdfList] = useState();
  const [uploadMessage, setUploadMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [typing, setTyping] = useState(false);

  // console.log(typing, "typing")

  useEffect(() => {
    // Scroll to the bottom of the chat display when chatHistory changes
    if (chatDisplayRef.current) {
      chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const clearChat = async (e) => {
    e.preventDefault();
    try {
      await axios.get(
        `${process.env.REACT_APP_BASE_URL}/?clearchat=${true}`, {
        headers: {
          'X-Token': "x-token",
        }
      }
      );
      setChatHistory();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/chat/${id}`, {
        headers: {
          'X-Token': "x-token",
        }
      });
      const pdfList = [];
      if (response.data.success) {
        response.data.data.all_pdf_files.forEach((pdf) => {
          pdfList.push({ value: pdf, label: pdf });
        });
      }
      setPdfList(pdfList);
      setChatHistory();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    fetchData()
  }, []);

  const ChangePdf = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}`, {
        headers: {
          'X-Token': "x-token",
        }
      });

      const pdfList = [];
      response.data.all_pdf_files.forEach((pdf) => {
        pdfList.push({ value: pdf, label: pdf });
      });
      setPdfList(pdfList);
      setChatHistory();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // const handleFileChange = (e) => {
  //     const { name, value, type } = e.target;
  //     console.log(value)
  //     const newValue = type === 'file' ? e.target.files[0] : value;
  //     setUserData(prevState => ({
  //         ...prevState,
  //         [name]: newValue
  //     }));
  // };

  const handleFileChange = (e) => {
    const { name, type, value } = e.target;

    if (name === "selected_pdf") {
      ChangePdf();
    }
    const selectedFile = type === "file" ? e.target.files[0] : value;

    if (type === "file" && selectedFile) {
      // Check if the selected file is a PDF
      if (selectedFile.type === "application/pdf") {
        // Set the selected PDF file in the component state
        setUserData((prevState) => ({
          ...prevState,
          [name]: selectedFile,
          pdf_file_name: selectedFile.name,
        }));
      } else {
        // Display an error message for non-PDF files
        // console.error('Invalid file type. Please select a PDF file.');
        setErrorMessage(true);
        setTimeout(() => {
          setErrorMessage(false);
        }, 3000);
        // Optionally, you can reset the input field here
        e.target.value = ""; // Reset the file input value to clear the selection
      }
    } else {
      // For non-file inputs, directly update the state with the value
      const newValue = e.target.value;
      setUserData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    }
  };

  // const handleSelectFile = (selectedFiles) => {
  //   const tempSelectedFiles = [];
  //   selectedFiles.forEach((file) => {
  //     tempSelectedFiles.push(file.value);
  //   });
  //   setSelectedPdfFiles(selectedFiles);
  //   setUserData((prevState) => ({
  //     ...prevState,
  //     selected_pdf: tempSelectedFiles,
  //   }));
  // };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // IMPORTANT_NOTE: Commented below code because now we are receiving streaming response.
      // const combinedData = {
      //   selected_pdf: Userdata.pdf_file
      //     ? Userdata.pdf_file.name
      //     : Userdata.selected_pdf
      //     ? Userdata.selected_pdf
      //     : "blank",
      //   userInput: Userdata.userInput,
      //   pdf_file: Userdata.pdf_file,
      // };

      if (Userdata.userInput) {
        setTyping(true);
      }
      const url = new URL(`/chat/${id}`, process.env.REACT_APP_BASE_URL);
      url.searchParams.append("selected_pdf", Userdata.selected_pdf);
      url.searchParams.append("userInput", Userdata.userInput);

      if (Userdata.pdf_file) {
        const base64 = await fileToBase64(Userdata.pdf_file);
        const encodedFile = encodeURIComponent(base64);
        url.searchParams.append("pdf_file", encodedFile);
        url.searchParams.append("pdf_file_name", Userdata.pdf_file_name);
      }
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Token": "x-token"
        },
      };


      try {
        const response = await fetch(url.toString(), options);
        if (!Userdata.userInput) {
          const tempPdfList = [];
          response.data.all_pdf_files.forEach((pdf) => {
            tempPdfList.push({ value: pdf, label: pdf });
          });
          setPdfList([...pdflist, ...tempPdfList]);
          setUserData((prevState) => ({
            ...prevState,
            userInput: "",
            pdf_file: null,
            pdf_file_name: "",
          }));
          if (fileInputRef?.current) {
            fileInputRef.current.value = "";
          }
          return;
        }

        if (!response.body) {
          throw new Error("ReadableStream not yet supported in this browser.");
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        setUserData((prevState) => ({
          ...prevState,
          userInput: "",
          pdf_file: null,
          pdf_file_name: "",
        }));
        if (chatHistory && chatHistory?.length > 0) {
          setChatHistory([
            ...chatHistory,
            {
              user_messages: { type: "user", message: Userdata.userInput },
              bot_messages: { type: "Remus", message: "" },
            },
          ]);
        } else {
          setChatHistory([
            {
              user_messages: { type: "user", message: Userdata.userInput },
              bot_messages: { type: "Remus", message: "" },
            },
          ]);
        }
        while (true) {
          const { done: complete, value } = await reader.read();
          if (complete) {
            break;
          }
          const data = decoder.decode(value);
          setChatHistory((prevState) => {
            // Create a copy of the previous state
            const updatedHistory = [...prevState];

            // Modify the last element
            updatedHistory[updatedHistory.length - 1] = {
              user_messages: { type: "user", message: Userdata.userInput },
              bot_messages: {
                type: "Remus",
                message:
                  updatedHistory[updatedHistory.length - 1].bot_messages
                    .message + data,
              },
            };

            // Return the updated state
            return updatedHistory;
          });
        }
      } catch (error) {
        console.error("Error fetching or processing response:", error);
        throw error;
      }
      // IMPORTANT_NOTE: Commented below code because now we are receiving streaming response.
      // if (Userdata.userInput) {
      //   setTyping(true);
      // }
      // if (Userdata.pdf_file) {
      //   setUploadMessage(true);
      //   setTimeout(() => {
      //     setUploadMessage(false);
      //   }, 4000);
      //   document.getElementById("pdfFile").value = "";
      // }

      // console.log("combinedData", combinedData);
      // const response = await axios.post(
      //   "https://api.remusengine.com/",
      //   combinedData,
      //   {
      //     responseType: "stream",
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );

      // response.data.on("data", (chunk) => {
      //   const data = JSON.parse(chunk);
      //   console.log("responceeeeee", data);
      //   // setChatHistory((prevHistory) => [...prevHistory, data]);
      // });

      // console.log("responceeeeee", response.data.chat_history);
      // if (response.data.chat_history) {
      //   setTyping(false);
      // }
      // setChatHistory(response.data.chat_history);
      // setPdfList(response.data.all_pdf_files);
      // setUserData({
      //   userInput: "",
      //   pdf_file: null,
      //   selected_pdf: response.data.name,
      // });

      // if (response.data.name) {
      //     console.log("response.data.name", response.data.name)

      //     try {
      //         const response = await axios.post('https://api.remusengine.com/', {
      //             headers: {
      //                 'Content-Type': 'multipart/form-data'
      //             },
      //         });

      //         setPdfList(response.data.all_pdf_files);

      //     } catch (error) {
      //         console.error('Error submitting form:', error);
      //     }

      // }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setTyping(false);
    }
  };

  // useEffect(() => {
  //     // Trigger animation after a delay (e.g., 500ms)
  //     const timeout = setTimeout(() => {
  //         setShowAnimation(true);
  //     }, 1000);

  //  Clear timeout on component unmount to prevent memory leaks
  //  return () => clearTimeout(timeout);
  //      }, []);

  // const openbot = () => {
  //     setShowAnimation(true);
  //     setTimeout(() => {
  //         setStartChat(true)
  //     }, 1000);
  //     console.log("startchat", startchat)
  // }

  return (
    <div
      style={{
        backgroundColor: "#0e2c23",
        color: "white",
        height: "100vh",
        width: "100vw",
      }}
    >
      {/* Popup for successful upload */}
      <div
        className="popup"
        id="popupMessage"
        style={{ display: uploadMessage ? "block" : "none" }}
      >
        Uploaded successfully ...
      </div>

      {/* Popup for error message */}
      <div
        className="Errorpopup"
        id="popupError"
        style={{ display: errorMessage ? "block" : "none" }}
      >
        Only PDF files are allowed...
      </div>

      <div className="Firstcontainer">
        <div className="column">
          <div className="main-container">
            <div id="chat-container">
              {/* Chat display */}
              <div id="chat-display" ref={chatDisplayRef}>
                <div>
                  {chatHistory?.length > 0 &&
                    chatHistory?.map((chat, index) => (
                      <div key={index}>
                        <div className="message">
                          {" "}
                          {chat.user_messages.message}
                        </div>
                        <div className="bot">
                          {" "}
                          <Markdown>{chat.bot_messages.message}</Markdown>
                        </div>
                        <br />
                      </div>
                    ))}
                  {
                    typing && <div className="message">
                      {" "}
                      {Userdata.userInput}
                    </div>
                  }
                  {typing ? <div className="loader"></div> : ""}
                </div>
              </div>

              {/* Chat input form */}
              <div id="chat-input">
                <form onSubmit={handleSubmit} style={{ margin: "unset" }}>
                  {/* <div>
                    <input
                      style={{ color: "whitesmoke" }}
                      type="file"
                      name="pdf_file"
                      id="pdfFile"
                      accept=".pdf"
                      ref={fileInputRef}
                      // value={Userdata.pdf_file}
                      onChange={handleFileChange}
                    />

                    <button
                      className="custom-button"
                      onClick={handleSubmit}
                      disabled={typing}
                      type="button"
                    >
                      Upload
                    </button>
                  </div> */}

                  {/* <select
                    className="options"
                    value={Userdata.selected_pdf}
                    name="selected_pdf" // Use userData.selected_pdf instead of setUserData.
                    onChange={handleFileChange}
                    // onClick={(e) => ChangePdf(e.target.value)}
                  >
                    <option value="blank">No File Selected</option>
                    {pdflist?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select> */}
                  {/* <div
                    style={{
                      width: "98.5%",
                      margin: "0.2%",
                      paddingTop: "5px",
                    }}
                  >
                    <Select
                      value={selectedPdfFiles}
                      onChange={(e) => handleSelectFile(e)}
                      options={pdflist}
                      isMulti
                      placeholder="Select Files"
                      className="basic-multi-select"
                    />
                  </div> */}

                  <input
                    type="text"
                    name="userInput"
                    placeholder="Start chatting with bot..."
                    style={{
                      position: "relative",
                      width: "96.5%",
                      padding: "8px",
                      margin: "0.2%",
                    }}
                    autoFocus
                    required
                    spellCheck="true"
                    value={typing ? "" : Userdata.userInput}
                    onChange={handleFileChange} // Add this line to handle user input changes
                  />
                  <div>
                    <button
                      className="custom-button"
                      type="submit"
                      disabled={typing}
                    >
                      Send
                    </button>
                    <button
                      className="custom-button"
                      style={{ textDecoration: "none", color: "whitesmoke" }}
                      disabled={typing}
                      onClick={clearChat}
                    >
                      Clear Chat
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatComponent;
